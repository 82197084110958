@import url(https://fonts.googleapis.com/css?family=Heebo:400,600,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
body {
    font-size: 16px;
    line-height: 1.4;
    font-family: Heebo;
}

html {
    height: 100%;
}

a {
    color: #3C29E6;
    font-family: Heebo;
    font-weight: 800;
}

a.danger-link {
    color: #ff0000;
}

.avatar-dropdown a {
    font-weight: normal !important;
}

.text-right {
    text-align: right;
}

.byline {
    font-size: 14px;
}

.ant-notification-notice-message {
    font-weight: bold;
}

.warning-notification .ant-notification-notice-message {
    color: #A35050;
}

.success-notification .ant-notification-notice-message {
    color: #929D2D;
}

.info-notification .ant-notification-notice-message {
    color: #4D638F;
}

.right {
    text-align: right;
}

.validation-msg {
    color: red;
    margin: 8px;
}

.hide {
    display: none;
}

.ant-select-selection {
    font-family: Heebo !important;
}

.modal-img {
    width: 100%;
    margin: auto;
    display: flex;
    margin-bottom: 8px;
    max-height: 60vh;
}


#tidio-chat-iframe {
    left: -10px !important;
}



@media screen and (max-width: 600px) {

    input,
    select {
        font-size: 11px !important;
    }

    input::placeholder,
    select::placeholder {
        font-size: 11px !important;
    }

    #tidio-chat-iframe {
        display: none !important;
    }
}

/* Syncfusion image editor*/

.e-toolbar-area {
  position: relative;
  top: 42px;
  width: 100%;
  z-index: 99;
}

